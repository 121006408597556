<template>
  <div>
    <el-button slot="append" type="text" :loading="loading" style="width:100px;" @click="getCode">{{getSmsText}}</el-button>
  </div>
</template>

<script>
export default {
  name: "CountTimer",
  props: ['loading', 'type'],
  data() {
    return {
      getSmsText: "获取验证码",
      timer: undefined
    }
  },
  methods: {
    getCode() {
      if (this.timer) return
      clearInterval(this.timer);
      this.$emit('getSmsCode', { type: this.type })
      this.clickE()
    },
    clickE() {
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            this.getSmsText = `(${this.count}s)`
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            this.getSmsText = `重发短信`
          }
        }, 1000)
      }
    }
  },
}
</script>

<style>
</style>