<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="login-logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="login-body">
        <div v-show="isLogin===true">
          <el-tabs v-model="activeName">
            <!-- 用户名登陆 -->
            <el-tab-pane label="账户登录" name="user">
              <el-form :model="userLoginForm" :rules="userLoginRules" ref="userLoginRules">
                <el-form-item style="margin-top: 20px" prop="phone">
                  <el-input clearable v-model="userLoginForm.phone" size="large" style="width: 300px" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 20px" prop="pwd">
                  <el-input clearable show-password v-model="userLoginForm.pwd" size="large" style="width: 300px" type="password" placeholder="密码" prefix-icon="el-icon-lock"></el-input>
                </el-form-item>
                <div class="divflexxb">
                  <el-checkbox v-model="userLoginForm.rememberMe">记住我</el-checkbox>
                  <el-button type="text" size="large" @click="isLogin=false">找回密码</el-button>
                </div>
                <el-form-item style="margin-top: 30px">
                  <el-button type="primary" style="width: 100%" size="large" @click="handleLogin">登录</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          
          </el-tabs>
        </div>
        <div v-show="isLogin === false">
          <el-form :model="findPwdForm" :rules="findPwdFormRules" ref="findPwdForm">
            <el-form-item style="margin-top: 20px" prop="phone">
              <el-input clearable v-model="findPwdForm.phone" size="large" style="width: 300px" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 20px" prop="smsCode">
              <el-input clearable v-model="findPwdForm.smsCode" size="large" style="width:300px;" type="text" placeholder="验证码" prefix-icon="el-icon-lock">
                <count-timer slot="append" :loading="SMSloading" @getSmsCode="getSmsCode" :type="2"></count-timer>
              </el-input>
            </el-form-item>
            <el-form-item style="margin-top: 20px" prop="pwd">
              <el-input clearable show-password v-model="findPwdForm.pwd" size="large" style="width: 300px" type="password" placeholder="密码" prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <div style="margin-top: 30px" class="divflexxb">
              <el-button type="primary" style="width: 100%" size="large" @click="resetPwd">重置密码</el-button>
              <el-button type="primary" style="width: 100%" size="large" @click="isLogin=true">去登录</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin, phoneLogin, findPadSendSms, findPwd, loginSendSms } from "@/api/user";
import CountTimer from './countTimer.vue'
import Cookies from "js-cookie"
import { encrypt, decrypt } from '@/utils/jsencrypt'
export default {
  name: "login",
  components: { CountTimer },
  created() {
    this.getCookie();
  },
  provide() {
    return { userInfo: this.userInfo }
  },

  data() {
    // 18716393365
    return {
      isLogin: true,
      activeName: 'user',
      isUser: true,
      isPhone: false,
      SMSloading: false,
      getSmsText: "获取验证码",
      userLoginForm: {
        phone: undefined,
        pwd: undefined
      },
      phoneLoginForm: {},
      findPwdForm: {},
      userLoginRules: {
        phone: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      phoneLoginRules: {
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
        ],
        smsCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
      findPwdFormRules: {
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
        ],
        smsCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
        ]
      }
    };
  },
  methods: {
    getSmsCode(e) {
      this.SMSloading = true
      switch (e.type) {
        //  获取手机登陆验证码
        case 1:
          loginSendSms(this.phoneLoginForm.phone).then((res) => {
            this.$message.success('手机登录验证码发送成功')
          })
          break;
        // 找回Miami发送验证码 
        case 2:
          findPadSendSms(this.findPwdForm.phone).then((res) => {
            if (res.code === 200) {
              this.$message.success('找回密码验证码发送成功')
            }
          })
          break;
      }
      this.SMSloading = false
    },
    getCookie() {
      const phone = Cookies.get("phone");
      const pwd = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.userLoginForm = {
        phone: phone === undefined ? this.userLoginForm.phone : phone,
        pwd: pwd === undefined ? this.userLoginForm.pwd : decrypt(pwd),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 登录请求
    handleLogin() {
      let params
      if (this.activeName === "user") {
        this.$refs["userLoginRules"].validate((valid) => {
          if (!valid) {
            return;
          } else {
            params = {
              phone: this.userLoginForm.phone,
              pwd: this.userLoginForm.pwd,
            };
            userLogin(params).then((res) => {
              if (res.code === 200) {
                if (this.userLoginForm.rememberMe) {
                  Cookies.set('phone', this.userLoginForm.phone, { expires: 30 })
                  Cookies.set('password', encrypt(this.userLoginForm.pwd), { expires: 30 })
                  Cookies.set('rememberMe', this.userLoginForm.rememberMe, { expires: 30 })
                } else {
                  Cookies.remove('phone')
                  Cookies.remove('password')
                  Cookies.remove('rememberMe')
                }
                Cookies.set('token', res.data.access_token, { expires: 30 })
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                this.$bus.emit('userInfo', res.data)
                localStorage.setItem('broadcastStatus', false)
                this.$router.push("/index");
                this.$message.success('登陆成功')
              }
            });
          }
        });
      } else if (this.activeName === "phone") {
        this.$refs["phoneLoginRules"].validate((valid) => {
          if (!valid) {
            return;
          } else {
            params = {
              phone: this.phoneLoginForm.phone,
              smsCode: this.phoneLoginForm.smsCode,
            };
            phoneLogin(params).then((res) => {
              if (res.code === 200) {
                Cookies.set('token', res.data.access_token, { expires: 30 })
                Cookies.set('userInfo', JSON.stringify(res.data))
                this.$router.push("/index");
                this.$message.success('登陆成功')
              }
            });
          }
        });
      }

    },
    // 找回Miami
    resetPwd() {
      findPwd(this.findPwdForm).then((res) => {
        if (res.code === 200) {
          this.$message.success('重置密码成功,请使用新密码登录')
          this.isLogin = true
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/login_bg.jpg");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.title {
  color: white;
  font-size: 30px;
  margin-top: 30px;
}

.login-box {
  width: 400px;
  height: 420px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0.95;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-logo {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 300px;
  text-align: center;
  padding: 10px 0;
  font-size: 30px;
}
.login-body {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}
</style>