import request from '@/utils/request'

/**
 * 登录
 */

export function userLogin(data) {
	return request({
	  url: '/api/boss/login',
	  method: 'post',
	  data
	})
  }

  // 验证码登陆
export function phoneLogin(data) {
	return request({
		url: '/api/boss/smsLogin',
		method: 'post',
		data
	  })
}

// 注销登陆
export function logout() {
	return request({
		url: '/api/boss/logout',
		method: 'get',
	  })
}

// 找回密码发送短信验证码
export function findPadSendSms(phone) {
	return request({
		url: `/api/boss/findPadSendSms/${phone}`,
		method: 'get'
	  })
}

// 获取登录验证码
export function loginSendSms(phone) {
	return request({
		url: `/api/boss/loginSendSms/${phone}`,
		method: 'get'
	  })
}

// 通过验证码找回密码
export function findPwd(query) {
	return request({
		url: "/api/boss/findPwd",
		method: 'post',
		data: query
	  })
}


// 修改密码
export function updatePad(data) {
	return request({
		url: "/api/boss/updatePad",
		method: 'post',
		data
	  })
}
// 月订单统计
export function summary(data) {
	return request({
		url: "/api/boss/summary",
		method: 'post',
		data
	  })
}


